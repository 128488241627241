<template>
    <NuxtLink
        :to="'/' + formattedLink"
        class="flex text-inherit no-underline md:flex-1 md:hover:cursor-pointer"
        @click="item.link?.anchor && scrollToId(item.link?.anchor)"
    >
        <div
            v-editable="item.vEditable"
            class="group flex flex-col gap-2 rounded-xl bg-white p-6 text-left shadow-card-lg md:hover:bg-brew md:hover:text-white"
        >
            <div class="mb-4 flex">
                <StoryblokImage
                    v-if="item.image"
                    class="aspect-auto"
                    :class="[
                        { 'md:group-hover:hidden': item.hoverImage },
                        imageSizeCss,
                    ]"
                    :src="item.image.src"
                    :alt="item.image.alt"
                />
                <StoryblokImage
                    v-if="item.hoverImage"
                    class="hidden aspect-auto md:group-hover:block"
                    :class="imageSizeCss"
                    :src="item.hoverImage.src"
                    :alt="item.hoverImage.alt"
                />
            </div>
            <div class="mb-4">
                <div
                    v-if="item.subtitle"
                    class="mb-2 text-sm font-semibold uppercase tracking-wide text-drip transition-colors md:group-hover:text-white"
                >
                    {{ item.subtitle }}
                </div>
                <div
                    class="text-[20px] font-bold text-grind transition-colors md:group-hover:text-white"
                >
                    {{ item.title }}
                </div>
            </div>
            <WysiwygContent
                v-if="item.content"
                :content="item.content"
                class="mb-4"
            />
            <div
                v-if="item.link"
                class="flex flex-1 flex-col justify-end text-brew transition-colors md:group-hover:text-white"
            >
                {{ item.link.label }}
            </div>
        </div>
    </NuxtLink>
</template>

<script setup lang="ts">
import DifferentiatorGridItemType from '~/types/DifferentiatorGridItemType';
import { stripTrailingSlash, scrollToId } from '~/utils/helpers';

const props = defineProps<{
    item: DifferentiatorGridItemType;
}>();

const imageSizeCss = computed(() => {
    let css = 'h-[50px]';
    if (props.item.imageSize) {
        if (props.item.imageSize === 'large') {
            css = 'h-[240px]';
        } else if (props.item.imageSize === 'medium') {
            css = 'h-[120px]';
        }
    }
    return css;
});

const formattedLink = computed(() => {
    if (props.item.link?.url !== undefined) {
        return stripTrailingSlash(props.item.link?.url);
    }

    return '';
});
</script>
